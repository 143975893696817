import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ProjectCustomUtils} from '../helpers/ProjectCustomUtils';
import {Feature, FeaturePermission} from '../models/db.model';

interface IMenuItem {
    type: string;       // Possible values: link/dropDown/icon/separator/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
}

interface IChildItem {
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private menuUser: IMenuItem = {
        name: 'User Management',
        type: 'link',
        tooltip: 'Users',
        icon: 'person',
        state: 'user',
    };

    // private menuCattleDetailReg: IMenuItem = {
    //     name: 'Cattle Management',
    //     type: 'link',
    //     tooltip: 'CDR',
    //     icon: 'library_add',
    //     state: 'cdr'
    // };

    private menuCattleDetailReg: IMenuItem = {
        name: 'Cattle Management',
        type: 'dropDown',
        tooltip: 'Multi Level',
        icon: 'library_add',
        state: 'cattle',
        sub: [
            {
                name: 'Cattle Registration', state: 'registration',
            },
            {
                name: 'Deworming', state: 'deworming',
            },
            {
                name: 'Reproduction', state: 'reproduction'
            },
            {
                name: 'Production', state: 'production'
            },
            {
                name: 'Treatment', state: 'treatment'
            },
            {
                name: 'Vaccination', state: 'vaccination'
            },
            {
                name: 'Weight', state: 'weight'
            },
        ]
    };

    private menuParameter: IMenuItem = {
        name: 'Animal Parameters',
        type: 'dropDown',
        tooltip: 'Multi Level',
        icon: 'format_align_center',
        state: 'parameter',
        sub: [
            {
                name: 'Cow', state: 'cow',
            },
            {
                name: 'Buffalo', state: 'buffalo'
            },
        ]
    };

    private menuMilkUnionReports: IMenuItem = {
        name: 'Milk Union Reports',
        type: 'dropDown',
        tooltip: 'Multi Level',
        icon: 'chrome_reader_mode',
        state: 'milk-union-reports',
        sub: [
            {
                name: 'Animal Wise', state: 'animal-wise',
            },
            {
                name: 'Projection Report', state: 'projection-report'
            },
            {
                name: 'Herd Wise', state: 'herd-wise',
            },
            {
                name: 'Parameter Wise', state: 'parameter-wise'
            }
        ]
    };

    // account master data
    private mMenuAccountMedicineType: IChildItem = {name: 'Medicine Type', state: 'Medicinetype'};
    private mMenuAccountMedicineLedger: IChildItem = {name: 'Medicine Ledger', state: 'Medicineledger'};

    // application data
    private mMenuUserGroup = {name: 'User Group', state: 'user_group'};

    // Common data
    private mMenuRegSpecies: IChildItem = {name: 'Species', state: 'species'};
    private mMenuRegBreed: IChildItem = {name: 'Breed', state: 'breed'};
    private mMenuRegVaccinationType: IChildItem = {name: 'Vaccination Type', state: 'vaccination_type'};
    private mMenuRegTreatmentComplaint: IChildItem = {name: 'Treatment Complaint', state: 'treatment_complaint'};
    private mMenuRegReproductiveProblem: IChildItem = {name: 'Reproductive Problem', state: 'reproductive_problem'};
    private mMenuRegDryOffReason: IChildItem = {name: 'Dry off Reason', state: 'dryoff_reason'};

    // health Data
    private mMenuAccountSystemAffected: IChildItem = {name: 'System Affected', state: 'Systemaffected'};
    private mMenuRegDiagnosis: IChildItem = {name: 'Diagnosis', state: 'diagnosis'};

    // Master data
    private mMenuRegCC: IChildItem = {name: 'CC', state: 'cc'};
    private mMenuRegZone: IChildItem = {name: 'Zone', state: 'zone'};
    private mMenuRegSemen: IChildItem = {name: 'Semen', state: 'semen'};
    private mMenuRegRoute: IChildItem = {name: 'Route/Herd', state: 'herd'};
    private mMenuRegLot: IChildItem = {name: 'Society/Lot', state: 'lot'};
    private mMenuRegFarmer: IChildItem = {name: 'Owner/Farmer', state: 'farmer'};
    private mMenuRegVLot: IChildItem = {name: 'Virtual Lot', state: 'virtual_lot'};
    private mMenuRegSire: IChildItem = {name: 'Sire', state: 'sire'};
    private mMenuRegPurchaseSale: IChildItem = {name: 'Purchase / Sale Party', state: 'purchase_sale'};
    private mMenuRegStaff: IChildItem = {name: 'Staff', state: 'staff'};
    private mMenuRegInsuranceCompany: IChildItem = {name: 'Insurance Company', state: 'insurance_company'};
    // private mMenuRegCM: IChildItem = {name: 'Container Master', state: 'container'};
    private mMenuRegSource: IChildItem = {name: 'Source', state: 'Source'};
    private mMenuRegCalfSchedule: IChildItem = {name: 'Calf Schedule', state: 'calf_schedule'};
    private mMenuRegSmsSetting: IChildItem = {name: 'Sms Settings', state: 'sms_settings'};
    private mMenuMasterMedicineroute: IChildItem = {name: 'Medicine Route', state: 'Medicineroute'};
    private mMenuMasterDewormingType: IChildItem = {name: 'Deworming Type', state: 'Dewormingtype'};
    // transfer Data
    private mMenuAnimalTransfer: IChildItem = {name: 'Animal', state: 'animal'};
    private mMenuFarmerTransfer: IChildItem = {name: 'Farmer', state: 'farmer'};
    private mMenuVillageTransfer: IChildItem = {name: 'Village', state: 'village'};
    private mMenuVirtualLotTransfer: IChildItem = {name: 'Virtual Lot', state: 'virtual-lot'};
    // listing Data
    private mMenuAlarm: IChildItem = {name: 'Alarm', state: 'alarm'};
    private mMenuDailyActionPlan: IChildItem = {name: 'Daily Action Plan', state: 'daily_action_plan'};

    iconMenu: IMenuItem[] = [
        {
            name: 'Overview',
            type: 'link',
            tooltip: 'Overview',
            icon: 'home',
            state: 'dashboard',
        },
        this.menuUser,
        this.menuCattleDetailReg,
        this.menuParameter,
        this.menuMilkUnionReports,
        {
            name: 'Master',
            type: 'dropDown',
            tooltip: 'Master Data',
            icon: 'memory',
            state: 'master',
            sub: [
                {
                    name: 'Account', type: 'dropDown', state: 'account', sub: [
                        this.mMenuAccountMedicineType,
                        this.mMenuAccountMedicineLedger,
                    ]
                },

                {
                    name: 'Application', type: 'dropDown', state: 'application', sub: [
                        this.mMenuUserGroup,
                    ]
                },

                {
                    name: 'Common', type: 'dropDown', state: 'common', sub: [
                        this.mMenuRegSpecies,
                        this.mMenuRegBreed,
                        this.mMenuRegVaccinationType,
                        this.mMenuRegReproductiveProblem,
                        this.mMenuRegTreatmentComplaint,
                        this.mMenuRegDryOffReason,
                    ]
                },

                {
                    name: 'Health', type: 'dropDown', state: 'health', sub: [
                        this.mMenuAccountSystemAffected,
                        this.mMenuRegDiagnosis
                    ]
                },
                
                {
                    name: 'Listing', type: 'dropDown', state: 'listing', sub: [
                        this.mMenuAlarm,
                        this.mMenuDailyActionPlan
                    ]
                },

                {
                    name: 'Master', type: 'dropDown', state: 'master', sub: [
                        this.mMenuRegCC,
                        this.mMenuRegStaff,
                        this.mMenuRegZone,
                        this.mMenuRegSemen,
                        this.mMenuRegRoute,
                        this.mMenuRegLot,
                        this.mMenuRegFarmer,
                        this.mMenuRegVLot,
                        this.mMenuRegSire,
                        this.mMenuRegPurchaseSale,
                        this.mMenuRegInsuranceCompany,
                        this.mMenuRegSource,
                        this.mMenuRegCalfSchedule,
                        this.mMenuRegSmsSetting,
                        this.mMenuMasterDewormingType,
                        this.mMenuMasterMedicineroute
                    ]
                },

                {
                    name: 'Transfer', type: 'dropDown', state: 'transfer', sub: [
                        this.mMenuAnimalTransfer,
                        this.mMenuFarmerTransfer,
                        this.mMenuVillageTransfer,
                        this.mMenuVirtualLotTransfer
                    ]
                }
            ],
        },
    ];

    // Icon menu TITLE at the very top of navigation.
    // This title will appear if any icon type item is present in menu.
    iconTypeMenuTitle = 'Frequently Accessed';
    adminMenu: IMenuItem[] = [
        {
            name: 'All Milk Union',
            type: 'link',
            tooltip: 'Dashboard',
            icon: 'dashboard',
            state: 'super-admin/dashboard',
        }
    ];

    userIconMenu: IMenuItem[];
    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    constructor() {
    }

    // Customizer component uses this method to changeId menu.
    // You can remove this method and customizer component.
    // Or you can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        switch (menuType) {
            case 'icon-menu':
                this.menuItems.next(this.iconMenu);
                console.log('navigation.service.publishNavigationChange: icon menue');
                break;
            case 'admin-menu':
                this.menuItems.next(this.adminMenu);
                console.log('navigation.service.publishNavigationChange: admin dashboard');
                break;
            case 'user-menu':
                this.menuItems.next(this.userIconMenu);
                console.log('navigation.service:publishNavigationChange: user menu dashboard');
                break;
            default:
                this.menuItems.next(this.iconMenu);
        }
    }

    createSuperAdminMenu() {

        const isSA = localStorage.getItem(ProjectCustomUtils.superAdminCheckLocalStorageKey);
        if (isSA) {
            const adminMenueObject: IMenuItem = {
                name: 'All Milk Union',
                type: 'link',
                tooltip: 'Dashboard',
                icon: 'dashboard',
                state: 'super-admin/dashboard',
            };
            this.iconMenu = this.iconMenu.filter(item => item.name !== 'All Milk Union');
            this.iconMenu.splice(0, 0, adminMenueObject);
            this.publishNavigationChange('icon-menu');
        } else {
            // check and remove super admin menue
            this.iconMenu = this.iconMenu.filter(item => item.name !== 'All Milk Union');
        }
    }

    createIconMenuBasedOnPermission() {
        // creating the user menu based on permission
        let featureList: FeaturePermission[] = JSON.parse(localStorage.getItem(ProjectCustomUtils.featureAccessListKey));

        let userMenuItems: IMenuItem[] = [{
            name: 'Overview',
            type: 'link',
            tooltip: 'Overview',
            icon: 'home',
            state: 'dashboard',
        }];
        let masterAccountMenuLastPush: IChildItem = {name: 'Account', type: 'dropDown', state: 'account', sub: []};
        let masterApplicationMenuLastPush: IChildItem = {name: 'Application', type: 'dropDown', state: 'application', sub: []};
        let masterCommonMenuLastPush: IChildItem = {name: 'Common', type: 'dropDown', state: 'common', sub: []};
        let masterHealthMenuLastPush: IChildItem = {name: 'Health', type: 'dropDown', state: 'health', sub: []};
        let masterListingMenuLastPush: IChildItem = {name: 'Listing', type: 'dropDown', state: 'listing', sub: []};
        let masterMasterMenuLastPush: IChildItem = {name: 'Master', type: 'dropDown', state: 'master', sub: []};
        let masterTransferMenuLastPush: IChildItem = {name: 'Transfer', type: 'dropDown', state: 'transfer', sub: []};
        let userGroupMMenuItemExist = false;

        const featureListLength = featureList.length;
        for (let i = 0; i < featureListLength; i++) {
            if (featureList[i].Value > 3) {
                switch (featureList[i].Name) {
                    case ProjectCustomUtils.featureUser:
                        userMenuItems.push(this.menuUser);
                        break;
                    case ProjectCustomUtils.featureCattleRegistration:
                        userMenuItems.push(this.menuCattleDetailReg);
                        break;
                    case ProjectCustomUtils.featureParameter:
                        userMenuItems.push(this.menuParameter);
                        break;
                    case ProjectCustomUtils.featureMilkUnionReports:
                        userMenuItems.push(this.menuMilkUnionReports);
                        break;
                    case ProjectCustomUtils.featureSpecies:
                        masterCommonMenuLastPush.sub.push(this.mMenuRegSpecies);
                        break;
                    case ProjectCustomUtils.featureBreed:
                        masterCommonMenuLastPush.sub.push(this.mMenuRegBreed);
                        break;
                    case ProjectCustomUtils.featureStaff:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegStaff);
                        break;
                    case ProjectCustomUtils.featureCC:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegCC);
                        break;
                    case ProjectCustomUtils.featureZone:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegZone);
                        break;
                    case ProjectCustomUtils.featureSire:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegSire);
                        break;
                    case ProjectCustomUtils.featureHerd:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegRoute);
                        break;
                    case ProjectCustomUtils.featureLot:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegLot);
                        break;
                    case ProjectCustomUtils.featureFarmer:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegFarmer);
                        break;
                    case ProjectCustomUtils.featureVirtualLot:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegVLot);
                        break;
                    case ProjectCustomUtils.featurePurchaseSaleParty:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegPurchaseSale);
                        break;
                    case ProjectCustomUtils.featureInsuranceCompany:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegInsuranceCompany);
                        break;
                    case ProjectCustomUtils.featureSemen:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegSemen);
                        break;
                    case ProjectCustomUtils.featureSource:
                        masterMasterMenuLastPush.sub.push(this.mMenuRegSource);
                        break;
                    case ProjectCustomUtils.featureAccountMedicine:
                        masterAccountMenuLastPush.sub.push(this.mMenuAccountMedicineType);
                        break;
                    case ProjectCustomUtils.featureAccountMedicineledger:
                        masterAccountMenuLastPush.sub.push(this.mMenuAccountMedicineLedger);
                        break;
                    case ProjectCustomUtils.featureAccountMedicineroute:
                        masterMasterMenuLastPush.sub.push(this.mMenuMasterMedicineroute);
                        break;
                    case ProjectCustomUtils.featureSystemAffected:
                        masterHealthMenuLastPush.sub.push(this.mMenuAccountSystemAffected);
                        break;
                    case ProjectCustomUtils.featureDewormingtype:
                        masterMasterMenuLastPush.sub.push(this.mMenuMasterDewormingType);
                        break;

                    case ProjectCustomUtils.featureAnimalTransfer:
                        masterTransferMenuLastPush.sub.push(this.mMenuAnimalTransfer);
                        break;
                    case ProjectCustomUtils.featureFarmerTransfer:
                        masterTransferMenuLastPush.sub.push(this.mMenuFarmerTransfer);
                        break;
                    case ProjectCustomUtils.featureVillageTransfer:
                        masterTransferMenuLastPush.sub.push(this.mMenuVillageTransfer);
                        break;
                    case ProjectCustomUtils.featureVirtualLotTransfer:
                        masterTransferMenuLastPush.sub.push(this.mMenuVirtualLotTransfer);
                        break;

                    case ProjectCustomUtils.featureAlarm:
                        masterListingMenuLastPush.sub.push(this.mMenuAlarm);
                        break;
                    case ProjectCustomUtils.featureDailyActionPlan:
                        masterListingMenuLastPush.sub.push(this.mMenuDailyActionPlan);
                        break;
                    // case ProjectCustomUtils.featureGroupMaster:
                    //     masterRegistrationMenuLastPush.sub.push(this.mMenuSmsSetting);
                    //     break;
                    case ProjectCustomUtils.featureUsergroup:
                        console.log('navigation.service:createIconMenuBasedOnPermission: THis will be taken care in bottom');
                        userGroupMMenuItemExist = true;
                        break;
                    default:
                        console.log('navigation.service:createIconMenuBasedOnPermission: ERROR ---> this should never occur check;',);
                }
            } else {
                console.log('navigation.service:createIconMenuBasedOnPermission: View Permission not granted ', featureList[i].Name);
            }
        }

        // Merge registration to master
        let masterMenu: IMenuItem = {
            name: 'Master',
            type: 'dropDown',
            tooltip: 'Master Data',
            icon: 'memory',
            state: 'master',
            sub: [
                masterAccountMenuLastPush,
                masterApplicationMenuLastPush,
                masterCommonMenuLastPush,
                masterHealthMenuLastPush,
                masterListingMenuLastPush,
                masterMasterMenuLastPush,
                masterTransferMenuLastPush
            ],
        };
        if (userGroupMMenuItemExist) {
            masterMenu.sub.push(this.mMenuUserGroup);
        }
        // push the master to userMenuitem
        userMenuItems.push(masterMenu);

        // const isSA = localStorage.getItem(ProjectCustomUtils.superAdminCheckLocalStorageKey);
        // if (isSA) {
        //     const adminMenueObject: IMenuItem = {
        //         name: 'All Milk Union',
        //         type: 'link',
        //         tooltip: 'Dashboard',
        //         icon: 'dashboard',
        //         state: 'super-admin/dashboard',
        //     };
        //     userMenuItems.splice(0, 0, adminMenueObject);
        // } else {
        //     // check and remove super admin menue
        //     userMenuItems = userMenuItems.filter(item => item.name !== 'All Milk Union');
        // }
        userMenuItems = userMenuItems.filter(item => item.name !== 'All Milk Union');
        this.userIconMenu = userMenuItems;

        this.publishNavigationChange('user-menu');
    }
}