import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {ProjectCustomUtils} from '../../helpers/ProjectCustomUtils';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const expired = ProjectCustomUtils.isTokenExpired();
        console.log('auth.guard:canActivate: checking auth guard token expired ? ', expired);
        console.log('auth.guard:canActivate: route ', route);
        console.log('auth.guard:canActivate: state ', state.url);
        if (!expired) {
            if (state.url === '/super-admin/dashboard') {
                //get the stored token
                const token = localStorage.getItem(ProjectCustomUtils.projectJWTToken);
                localStorage.clear();
                // set item
                if (token) {
                    localStorage.setItem(ProjectCustomUtils.projectJWTToken, token);
                } else {
                    this.router.navigate(['/sessions/signin']);
                    return false;
                }
                const decodedToken = ProjectCustomUtils.decodeTokenAndStoreCredentials();
                console.log('auth.guard:canActivate: super admin url ', true);
                if (decodedToken.su) {
                    //check if token authentic via public key
                    return true;
                } else {
                    this.router.navigate(['/sessions/signin']);
                    return false;
                }
            } else {
                console.log('auth.guard:canActivate: super admin url ', false);
                return true;
            }
        }
        console.log('auth.guard:canActivate: Token expired redirecting to login ', expired);
        this.router.navigate(['/sessions/signin']);
        return false;
    }
}