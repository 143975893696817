import {NgModule, ErrorHandler} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig} from '@angular/material';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';


import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ErrorHandlerService} from './shared/services/error-handler.service';
import {RequestInterceptor} from './shared/request.interceptor';
import {JwtModule} from '@auth0/angular-jwt';
import {ProjectCustomUtils} from './shared/helpers/ProjectCustomUtils';

import 'chartjs-plugin-labels';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

export function jwtTokenGetter() {
    return localStorage.getItem(ProjectCustomUtils.projectJWTToken)
  }

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: false
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        PerfectScrollbarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(rootRouterConfig, {useHash: false}),
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
                // () => {
                //     return localStorage.getItem(ProjectCustomUtils.projectJWTToken);
                // },
                whitelistedDomains: ['http://localhost:2000'],
                blacklistedRoutes: ['/auth/login'],
            }
        }),
    ],
    declarations: [AppComponent,],
    providers: [
        {provide: ErrorHandler, useClass: ErrorHandlerService},
        {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        }

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}