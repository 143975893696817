import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {ProjectCustomUtils} from './shared/helpers/ProjectCustomUtils';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    companyName = new Subject<string>();

    constructor() {
    }

    set companyNameSetter(name: string) {
        this.companyName.next(name);
        localStorage.setItem(ProjectCustomUtils.companyNameLocalStorageKey, name);
    }

    get companyNameGetter(): string {
        return localStorage.getItem(ProjectCustomUtils.companyNameLocalStorageKey);
    }

    refreshName() {
        this.companyName.next(this.companyNameGetter);
    }
}
