import {Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {NavigationService} from '../../../shared/services/navigation.service';
import {ThemeService} from '../../services/theme.service';
import {Subscription} from 'rxjs';
import {ILayoutConf, LayoutService} from 'app/shared/services/layout.service';
import {ProjectCustomUtils} from '../../helpers/ProjectCustomUtils';

@Component({
    selector: 'app-sidebar-side',
    templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
    public menuItems: any[];
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    public layoutConf: ILayoutConf;
    ready = false;
    username = 'NA';

    constructor(
        private navService: NavigationService,
        public themeService: ThemeService,
        private layout: LayoutService, private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {

        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

        this.layoutConf = this.layout.layoutConf;
        this.username = localStorage.getItem(ProjectCustomUtils.usernameStorageKey);
    }

    ngAfterViewInit() {
        console.log('sidebar-side.component:ngAfterViewChecked: ');
        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
            this.menuItems = menuItem;
            //Checks item list has any icon type.
            this.hasIconTypeMenuItem = !!this.menuItems.filter(
                item => item.type === 'icon'
            ).length;
            this.ready = true;
            this.cdRef.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }

    toggleCollapse() {
        if (
            this.layoutConf.sidebarCompactToggle
        ) {
            this.layout.publishLayoutChange({
                sidebarCompactToggle: false
            });
        } else {
            this.layout.publishLayoutChange({
                // sidebarStyle: "compact",
                sidebarCompactToggle: true
            });
        }
    }
}
