import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appNoDecimal]'
})
export class NoDecimalDirective {

    constructor(private el: ElementRef) {
    }

    @Input() appNoDecimal: boolean;

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        let e = <KeyboardEvent> event;
        if (this.appNoDecimal) {
            return e.charCode >= 48 && e.charCode <= 57;
        }
    }

}
