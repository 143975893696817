import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ProjectCustomUtils} from './helpers/ProjectCustomUtils';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('RequestInterceptor : intercept : Interceptred request : ', req);
        const authToken = 'Bearer ' + localStorage.getItem(ProjectCustomUtils.projectJWTToken);
        const copiedRequest = req.clone({headers: req.headers.set('Authorization', authToken)});
        return next.handle(copiedRequest).pipe(tap(event => {
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.router.navigate(['/sessions/signin']);
                    }
                }
            }
        ));
    }

}
