import {Routes} from '@angular/router';
import {AdminLayoutComponent} from './shared/components/layouts/admin-layout/admin-layout.component';
import {AuthGuard} from './shared/services/auth/auth.guard';
import {AuthLayoutComponent} from './shared/components/layouts/auth-layout/auth-layout.component';

export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: '/sessions/signin',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                loadChildren: './views/sessions/sessions.module#SessionsModule',
                data: {title: 'Session'},
            }
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        // canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: './views/dashboard/dashboard.module#DashboardModule',
                data: {title: 'Overview', breadcrumb: 'Overview'}
            },
            {
                path: 'user',
                loadChildren: './views/user-managment/user-managment.module#UserManagmentModule',
                data: {title: 'User Managment', breadcrumb: 'User Management'}
            },
            {
                path: 'cattle',
                loadChildren: './views/cattle-details-and-registration/cattle-details-and-registration.module#CattleDetailsAndRegistrationModule',
                data: {title: 'Cattle details and registration', breadcrumb: 'Cattle details'}
            },
            {
                path: 'parameter',
                loadChildren: './views/parameter-settings/parameter-settings.module#ParameterSettingsModule',
                data: {title: 'Parameter Settings', breadcrumb: 'Parameter'}
            },
            {
                path: 'master',
                loadChildren: './views/master-data/master-data.module#MasterDataModule',
                data: {title: 'Master Data', breadcrumb: 'Master'}
            },
            {
                path: 'super-admin',
                loadChildren: './views/admin/admin.module#AdminModule',
                data: {title: 'Super Admin', breadcrumb: 'Super-admin'}
            },
            {
                path: 'milk-union-reports',
                loadChildren: './views/milk-union-reports/milk-union-reports.module#MilkUnionReportsModule',
                data: {title: 'Milk Union Reports', breadcrumb: 'Milk Union Reports'}
            }
        ],
    },
    /*{
        path: '**',
        redirectTo: 'sessions/404'
    }*/
];

