import {FormControl, FormGroup} from '@angular/forms';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HerdmanJwtToken} from '../models/db.model';

export class ProjectCustomUtils {
    static superAdminCheckLocalStorageKey = 'su';
    static userIdStorageKey = 'uid';
    static companyIdLocalStorageKey = 'cid';
    static companyNameLocalStorageKey = 'cname';
    static usernameStorageKey = 'username';
    static featureAccessListKey = 'feature';
    static projectJWTToken = 'herdmanToken';
    static localStorageCredentialsStored = 'stored';

    // permission String
    static featureUser = 'User Profile';
    static featureUserAccessPermission = 'User Access Permission';
    static featureUsergroup = 'Usergroup';
    static featureUsergroupPermission = 'User Group Permission';
    static featureBreed = 'Breed';
    static featureSpecies = 'Species';
    static featureCC = 'CC';
    static featureFarmer = 'Farmer';
    static featureHerd = 'Herd';
    static featureLot = 'Lot';
    static featureSire = 'Sire';
    static featureStaff = 'Staff';
    static featureZone = 'Zone';
    static featureVirtualLot = 'VirtualLot';
    static featureSemen = 'Semen';
    static featurePurchaseSaleParty = 'PurchaseSaleParty';
    static featureInsuranceCompany = 'InsuranceCompany';
    static featureParameter = 'Parameter';
    static featureCattleAnalytics = 'CattleAnalytics';
    static featureCattleRegistration = 'CattleRegistration';
    static featureSource = 'Source';
    static featureMilkUnionReports = "MilkUnionReports";

    static featureGroupUser = 'USER';
    static featureGroupMaster = 'MASTER';
    static featureGroupMasterRegister = 'MASTER-REGISTRATION';
    static featureGroupMsterUsergroup = 'MASTER-USERGROUP';
    static featureGroupParameter = 'PARAMETER';
    static featureGroupCattle = 'CATTLE';
    static featureGroupMobile = 'MOBILE';
    static featureGroupMilkUnionReports = "MILKUNIONREPORTS";

    static featureAccountMedicine = 'Medicine Type';
    static featureAccountMedicineledger = 'Medicine Ledger';
    static featureAccountMedicineroute = 'Medicine Route';

    static featureSystemAffected = 'Health';

    static featureDewormingtype = 'Deworming';

    static featureAnimalTransfer = 'AnimalTransfer';
    static featureFarmerTransfer = 'FarmerTransfer';
    static featureVillageTransfer = 'VillageTransfer';
    static featureVirtualLotTransfer = 'VirtualLotTransfer';

    static featureAlarm = 'Alarm';
    static featureDailyActionPlan = 'DailyActionPlan';

    static featureGroupHeadingUser = 'User management';
    static featureGroupHeadingMasterData = 'Master';
    static featureGroupHeadingRegister = 'Register';
    static featureGroupHeadingCattle = 'Cattle management';
    static featureGroupHeadingParameter = 'Animal parameter';
    static featureGroupHeadingUsergroup = 'User group anagement';
    static featureGroupHeadingMobileOnly = 'Mobile app features';
    static featureGroupHeadingMilkUnionReports = 'Milk Union Reports';

    static accordionUIDUser = 1;
    static accordionUIDCattle = 2;
    static accordionUIDParameter = 3;
    static accordionUIDRegistration = 4;
    static accordionUIDMaster = 5;
    static accordionUIDUsergroup = 6;
    static accordionUIDMobile = 7;
    static accordionUIDMilkUnionReports = 8;

    public static getIndexBy(array: Array<{}>, {name, value}): number {
        for (let i = 0; i < array.length; i++) {
            if (array[i][name] === value) {
                return i;
            }
        }
        return -1;
    }

    public static getNumberOfDigits(num: number): number {
        return Math.log(num) * Math.LOG10E + 1 | 0;
    }

    public static getErrorMessage(control: FormControl, formFieldName: String): string {
        if (control.hasError('required')) {
            return formFieldName + ' required';
        } else if (control.hasError('min')) {
            const digit = ProjectCustomUtils.getNumberOfDigits(control.getError('min').min);
            return formFieldName + ' should be atleast ' + digit + ' digits';
        } else if (control.hasError('max')) {
            const digit = ProjectCustomUtils.getNumberOfDigits(control.getError('max').max);
            return formFieldName + ' should not be greater than ' + digit + ' digits';
        } else if (control.hasError('pattern')) {
            return formFieldName + ' Invalid characters like full stop, comma, special characters or numbers entered please check';
        } else if (control.hasError('exist')) {
            return formFieldName + ' already exist, please enter another';
        } else if (control.hasError('minlength')) {
            return formFieldName + ' is not of minimum lenght, please enter more char';
        }
    }

    public static checkIfKeyExistInLocalStorage(key: string): boolean {
        if (localStorage.getItem(key) !== null) {
            return true;
        } else {
            return false;
        }
    }

    public static checkIfSuperAdmin(): boolean {
        return localStorage.getItem(ProjectCustomUtils.superAdminCheckLocalStorageKey) !== null;

    }

    public static checkIfAdmin(): boolean {
        return localStorage.getItem(ProjectCustomUtils.usernameStorageKey).toLocaleLowerCase().includes('admin');
    }

    public static groupBy(collection, property) {
        const groups = collection.reduce((groups, item) => {
            const group = (groups[item[property]] || []);
            group.push(item);
            groups[item[property]] = group;
            return groups;
        }, {});

        return groups;
    }

    public static convertReactiveFormValueToFormData(form: FormGroup): FormData {
        const data = form.value;
        let form_data = new FormData();
        // appended the form data
        for (let key in data) {
            if (key !== 'photo') {
                form_data.append(key, data[key]);
            }
        }

        if (data.photo.files) {
            form_data.append('photo', data.photo.files[0]);
        }
        return form_data;
    }

    public static storeJWTToken(token) {
        localStorage.setItem(ProjectCustomUtils.projectJWTToken, token);
    }

    public static decodeTokenAndStoreCredentials(): HerdmanJwtToken {
        const myRawToken = localStorage.getItem(ProjectCustomUtils.projectJWTToken);
        if (myRawToken) {
            const decodedToken: HerdmanJwtToken = new JwtHelperService().decodeToken(myRawToken);
            const credentialStored = localStorage.getItem(ProjectCustomUtils.localStorageCredentialsStored);
            if (!credentialStored) {
                if (decodedToken.su) {
                    localStorage.setItem(ProjectCustomUtils.superAdminCheckLocalStorageKey, decodedToken.su + '');
                    localStorage.setItem(ProjectCustomUtils.userIdStorageKey, decodedToken.userID.toString());
                    localStorage.setItem(ProjectCustomUtils.usernameStorageKey, decodedToken.username);
                    localStorage.setItem(ProjectCustomUtils.companyNameLocalStorageKey, decodedToken.company);
                } else {
                    // normal user
                    localStorage.setItem(ProjectCustomUtils.userIdStorageKey, decodedToken.userID.toString());
                    localStorage.setItem(ProjectCustomUtils.usernameStorageKey, decodedToken.username);
                    localStorage.setItem(ProjectCustomUtils.companyNameLocalStorageKey, decodedToken.company);
                    localStorage.setItem(ProjectCustomUtils.featureAccessListKey, JSON.stringify(decodedToken.feature));
                }
                localStorage.setItem(ProjectCustomUtils.localStorageCredentialsStored, 'true');
            }
            return decodedToken;
        } else {
            return undefined;
        }
    }

    public static isTokenExpired(): boolean {
        const myRawToken = localStorage.getItem(ProjectCustomUtils.projectJWTToken);
        if (myRawToken) {
            return new JwtHelperService().isTokenExpired(myRawToken);
        } else {
            return true;
        }
    }

    public static convertDateTimeToDateStringOnly(date: string): string {
        if (date !== '' && date !== null && date !== undefined) {
            return new Date(date).toISOString().split('T')[0];
        }
    }
}